<template>
  <v-row>
    <v-col
      cols="12"
      class="py-0"
    >
      <template v-if="showInvoiceItemTemplateForm">
        <h3 class="mb-6 black--text">
          Dodaj usługę
        </h3>
        <v-autocomplete
          :items="invoiceItemTemplates"
          label="Usługa"
          item-text="name"
          return-object
          placeholder="Wybierz ze zdefiniowanych usług"
          outlined
          hide-details
          dense
          class="mb-6"
          @change="chooseInvoiceItemTemplate"
        />
        <v-form
          id="addNewInvoiceItemTemplateForm"
          ref="addNewInvoiceItemTemplateForm"
          class="custom-form"
          @submit.prevent="addNewCustomService"
        >
          <InvoiceItemTemplateForm
            :invoice-item-template="invoiceItemTemplate"
            @update="updateFormValue"
          />
        </v-form>
      </template>
      <template v-else>
        <h3 class="mb-6 black--text">
          Usługi
        </h3>
        <template v-if="operatingCustomServices.length">
          <div
            v-for="(service, index) in operatingCustomServices"
            :key="'service-' + index"
            class="d-flex align-center"
          >
            <v-row>
              <v-col cols="5">
                <v-text-field
                  v-model="operatingCustomServices[index].name"
                  outlined
                  label="Nazwa usługi"
                  :rules="[rules.required]"
                  validate-on-blur
                  placeholder="Wpisz nazwę usługi"
                  @change="updateCustomServices"
                />
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="operatingCustomServices[index].netValue"
                  outlined
                  label="Cena netto"
                  :rules="[
                    rules.required,
                    rules.money,
                    rules.lowerThan(10000001)
                  ]"
                  suffix="PLN"
                  validate-on-blur
                  placeholder="Wpisz cenę netto usługi [PLN]"
                  @change="updateCustomServices"
                />
              </v-col>
              <v-col cols="2">
                <v-text-field
                  v-model="operatingCustomServices[index].vatPercentage"
                  outlined
                  label="VAT"
                  :rules="[rules.required, rules.lowerThan(101)]"
                  validate-on-blur
                  suffix="%"
                  placeholder="Wpisz stawkę vat [%]"
                  @change="updateCustomServices"
                />
              </v-col>
              <v-col cols="2">
                <v-text-field
                  v-model.number="operatingCustomServices[index].quantity"
                  outlined
                  label="Ilość"
                  :rules="[rules.number, rules.lowerThan(101), rules.greaterThan(0)]"
                  type="number"
                  validate-on-blur
                  placeholder="1"
                  @change="updateCustomServices"
                  @wheel="$event.target.blur()"
                />
              </v-col>
            </v-row>
            <v-btn
              icon
              small
              class="mb-5 ml-1"
              @click="removeCustomService(index)"
            >
              <v-icon color="error">
                mdi-close
              </v-icon>
            </v-btn>
          </div>
        </template>
      </template>
    </v-col>

    <v-col
      cols="12"
      class="py-0 mt-n2"
    >
      <v-btn
        v-if="showInvoiceItemTemplateForm"
        text
        class="mr-1"
        color="primary"
        small
        type="submit"
        form="addNewInvoiceItemTemplateForm"
      >
        Dodaj usługę
      </v-btn>
      <v-btn
        v-if="showInvoiceItemTemplateForm"
        text
        class="mr-1"
        color="primary"
        small
        @click="submitNewInvoiceItemTemplateForm"
      >
        Dodaj i zapisz usługę
      </v-btn>
      <v-input
        v-else-if="
          !showInvoiceItemTemplateForm &&
            !operatingCustomServices.length
        "
        :value="operatingCustomServices"
        :rules="[rules.required]"
        hide-details
        class="validated-label d-inline-block"
      >
        <h4>
          Brak usług
        </h4>
      </v-input>
      <v-btn
        text
        :color="showInvoiceItemTemplateForm ? 'black' : 'primary'"
        small
        @click="
          showInvoiceItemTemplateForm = !showInvoiceItemTemplateForm
        "
      >
        {{ showInvoiceItemTemplateForm ? 'Anuluj' : 'Dodaj usługę' }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import InvoiceItemTemplateForm from './InvoiceItemTemplateForm'
import { parseAsBasicUnit } from '../../../utils'
import updateFormValue from '../../../mixins/updateFormValue'
import rules from '../../../utils/validators'
import { mapState, mapActions } from 'vuex'
export default {
  props: {
    customServices: {
      type: Array,
      required: true,
    },
  },
  mixins: [updateFormValue],
  components: {
    InvoiceItemTemplateForm,
  },
  data: () => ({
    rules,
    showInvoiceItemTemplateForm: true,
    operatingCustomServices: [],
    invoiceItemTemplate: {},
  }),
  computed: {
    ...mapState({
      invoiceItemTemplates: state => state.invoiceItemTemplates.items,
      department: state => state.core.department,
    }),
  },
  mounted() {
    this.getInvoiceItemTemplates()
    this.operatingCustomServices = [...this.customServices]
  },
  methods: {
    ...mapActions({
      getInvoiceItemTemplates: 'invoiceItemTemplates/getItems',
      addNewInvoiceItemTemplate: 'invoiceItemTemplates/addNewItem',
    }),
    async updateCustomServices() {
      await this.$nextTick()
      this.$emit('update', this.operatingCustomServices)
    },
    chooseInvoiceItemTemplate(template) {
      this.invoiceItemTemplate = {
        ...template,
        netValue: template.netValue / 100
      }
    },
    addNewCustomService() {
      if (this.$refs.addNewInvoiceItemTemplateForm.validate()) {
        this.operatingCustomServices.push({ ...this.invoiceItemTemplate, quantity: 1 })
        this.updateCustomServices()
        this.invoiceItemTemplate = {}
        this.showInvoiceItemTemplateForm = false
      }
    },
    removeCustomService(index) {
      this.$delete(this.operatingCustomServices, index)
      this.updateCustomServices()
    },
    submitNewInvoiceItemTemplateForm() {
      if (this.$refs.addNewInvoiceItemTemplateForm.validate()) {
        const netValue = parseAsBasicUnit(
          this.invoiceItemTemplate.netValue,
          100
        )
        const params = {
          ...this.invoiceItemTemplate,
          netValue,
          departmentId: this.department.id
        }

        this.addNewInvoiceItemTemplate({ params }).then(template => {
          this.getInvoiceItemTemplates()
          this.showInvoiceItemTemplateForm = false
          this.operatingCustomServices.push({
            ...template,
            quantity: 1,
            netValue: template.netValue / 100
          })
          this.updateCustomServices()
          this.invoiceItemTemplate = {}
        })
      }
    },
  }
}
</script>
